/* eslint-disable no-param-reassign */

import type { HotelRoomTableSearchParams } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type HotelRoomTableSearchParamsState = {
  params: HotelRoomTableSearchParams;
};
const initialState: HotelRoomTableSearchParamsState = {
  params: {
    page: 1,
    limit: 10,
    keyword: "",
  },
};

const hotelRoomSearchParamsSlice = createSlice({
  name: "hotel-rooms-search-params",
  initialState,

  reducers: {
    setPageNumber: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.params.page = action.payload;
    },
    setPageLimit: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.params.limit = action.payload;
    },
    setKeyword: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.params.keyword = action.payload;
    },
    resetPageNumber: (state) => {
      const draft = state;
      draft.params.page = initialState.params.page;
    },
    resetParams: () => {
      return initialState;
    },
  },
});

export const { setPageNumber, setPageLimit, setKeyword, resetPageNumber, resetParams } =
  hotelRoomSearchParamsSlice.actions;

export const { reducer: hotelRoomSearchParamsReducer } = hotelRoomSearchParamsSlice;
