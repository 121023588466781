import { createEmployeePageReducer } from "./create-employee-page/slice";
import { createServicePageStateReducer } from "./create-service-page-state/slice";
import { currentUserReducer } from "./current-user/slice";
import { employeeDetailsReducer } from "./employee-details-page/slice";
import { EmployeesReducer } from "./employees/slice";
import { employeesFiltersReducer } from "./employees-filters/slice";
import { employeesPageModalsReducer } from "./employees-page-modals/slice";
import { employeesSearchParamsReducer } from "./employees-search-params/slice";
import { faqQuestionsReducer } from "./faq-questions/slice";
import { faqQuestionsPageModalsReducer } from "./faq-questions-page-modals/slice";
import { forgotPasswordPageReducer } from "./forgot-password-page/slice";
import { guestNeedsFiltersReducer } from "./guest_needs_filters/slice";
import { guestStaysFiltersReducer } from "./guest_stays_filters/slice";
import { guestConversationsReducer } from "./guest-conversations/slice";
import { guestConversationsPageModalsReducer } from "./guest-conversations-page-modals/slice";
import { guestConversationsSearchParamsReducer } from "./guest-conversations-search-params/slice";
import { guestDetailsReducer } from "./guest-details-page/slice";
import { guestsFiltersReducer } from "./guest-filters/slice";
import { guestNeedTasksReducer } from "./guest-need-tasks/slice";
import { guestNeedTasksSearchParamsReducer } from "./guest-need-tasks-search-params/slice";
import { guestNeedsReducer } from "./guest-needs/slice";
import { guestNeedsSearchParamsReducer } from "./guest-needs-search-params/slice";
import { guestStaysReducer } from "./guest-stays/slice";
import { guestStaysSearchParamsReducer } from "./guest-stays-search-params/slice";
import { guestsReducer } from "./guests/slice";
import { guestsPageModalsReducer } from "./guests-page-modals/slice";
import { guestSearchParamsReducer } from "./guests-search-params/slice";
import { hotelRoomsReducer } from "./hotel-rooms/slice";
import { hotelRoomsPageModalsReducer } from "./hotel-rooms-page-modals/slice";
import { hotelRoomSearchParamsReducer } from "./hotel-rooms-search-params/slice";
import { imageViewerReducer } from "./image-viewer/slice";
import { importGuestsPageReducer } from "./import-guests-page/slice";
import { notificationChannelsReducer } from "./notification-channels/slice";
import { notificationChannelsFiltersReducer } from "./notification-channels-filters/slice";
import { notificationChannelsPageModalsReducer } from "./notification-channels-page-modals/slice";
import { notificationChannelsSearchParamsReducer } from "./notification-channels-search-params/slice";
import { notificationsHistoryFiltersReducer } from "./notification-histories-filters/slice";
import { notificationsHistorySearchParamsReducer } from "./notification-histories-search-params/slice";
import { notificationsHistoryReducer } from "./notifications-history/slice";
import { operationDetailsReducer } from "./operation-details-page/slice";
import { operationsReducer } from "./operations/slice";
import { operationsFiltersReducer } from "./operations-filters/slice";
import { operationsSearchParamsReducer } from "./operations-search-params/slice";
import { serviceDetailsReducer } from "./service-details-page/slice";
import { servicesFiltersReducer } from "./service-filters/slice";
import { servicesReducer } from "./services/slice";
import { servicesPageModalsReducer } from "./services-page-modals/slice";
import { servicesSearchParamsReducer } from "./services-search-params/slice";
import { snackBarReducer } from "./snack-bar/slice";
import { staysPageModalsReducer } from "./stays-page-modals/slice";
import { taskDetailsReducer } from "./task-details/slice";
import { tasksReducer } from "./tasks/slice";
import { tasksFiltersReducer } from "./tasks-filters/slice";
import { tasksPageReducer } from "./tasks-page/slice";
import { tasksSearchParamsReducer } from "./tasks-search-params/slice";
import { teamOperationDetailsReducer } from "./team-operation-details-page/slice";
import { teamOperationsReducer } from "./team-operations/slice";
import { teamOperationsFiltersReducer } from "./team-operations-filters/slice";
import { teamOperationsPageModalsReducer } from "./team-operations-page-modals/slice";
import { teamOperationsSearchParamsReducer } from "./team-operations-search-params/slice";
import { TeamsReducer } from "./teams/slice";
import { teamsPageModalsReducer } from "./teams-page-modals/slice";
import { teamsSearchParamsReducer } from "./teams-search-params/slice";
import { zonesPageReducer } from "./zones-page/slice";

import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
  reducer: {
    currentUser: currentUserReducer,
    snackBar: snackBarReducer,
    taskFilters: tasksFiltersReducer,
    tasks: tasksReducer,
    tasksSearchParams: tasksSearchParamsReducer,
    taskDetails: taskDetailsReducer,
    guests: guestsReducer,
    guestSearchParams: guestSearchParamsReducer,
    guestsFilters: guestsFiltersReducer,
    guestPageModals: guestsPageModalsReducer,
    guestNeeds: guestNeedsReducer,
    guestNeedsSearchParams: guestNeedsSearchParamsReducer,
    guestNeedsFilters: guestNeedsFiltersReducer,
    guestStays: guestStaysReducer,
    guestStaysSearchParams: guestStaysSearchParamsReducer,
    guestStaysFilters: guestStaysFiltersReducer,
    guestDetails: guestDetailsReducer,
    guestConversations: guestConversationsReducer,
    guestConversationsSearchParams: guestConversationsSearchParamsReducer,
    guestConversationsPageModals: guestConversationsPageModalsReducer,
    importGuestsPage: importGuestsPageReducer,
    forgotPasswordPage: forgotPasswordPageReducer,
    guestNeedTasks: guestNeedTasksReducer,
    guestNeedTasksSearchParams: guestNeedTasksSearchParamsReducer,
    stayPageModals: staysPageModalsReducer,
    teams: TeamsReducer,
    teamsSearchParams: teamsSearchParamsReducer,
    createEmployeePage: createEmployeePageReducer,
    employees: EmployeesReducer,
    employeesSearchParams: employeesSearchParamsReducer,
    employeesFilters: employeesFiltersReducer,
    employeesPageModals: employeesPageModalsReducer,
    employeeDetails: employeeDetailsReducer,
    teamsPageModals: teamsPageModalsReducer,
    createServicePageState: createServicePageStateReducer,
    services: servicesReducer,
    servicesFilters: servicesFiltersReducer,
    serviceSearchParams: servicesSearchParamsReducer,
    serviceDetails: serviceDetailsReducer,
    servicesPageModals: servicesPageModalsReducer,
    teamOperations: teamOperationsReducer,
    teamOperationsFilters: teamOperationsFiltersReducer,
    teamOperationsSearchParams: teamOperationsSearchParamsReducer,
    teamOperationsPageModals: teamOperationsPageModalsReducer,
    teamOperationDetails: teamOperationDetailsReducer,
    notificationChannels: notificationChannelsReducer,
    notificationChannelsPageModals: notificationChannelsPageModalsReducer,
    notificationChannelsSearchParams: notificationChannelsSearchParamsReducer,
    notificationChannelsFilters: notificationChannelsFiltersReducer,
    faqQuestions: faqQuestionsReducer,
    faqQuestionPageModals: faqQuestionsPageModalsReducer,
    imageViewerState: imageViewerReducer,
    notificationsHistory: notificationsHistoryReducer,
    notificationsHistoryFilters: notificationsHistoryFiltersReducer,
    notificationsHistorySearchParams: notificationsHistorySearchParamsReducer,
    zonesPageState: zonesPageReducer,
    hotelRooms: hotelRoomsReducer,
    hotelRoomSearchParams: hotelRoomSearchParamsReducer,
    hotelRoomsPageModals: hotelRoomsPageModalsReducer,
    tasksPage: tasksPageReducer,
    operations: operationsReducer,
    operationsFilters: operationsFiltersReducer,
    operationsSearchParams: operationsSearchParamsReducer,
    operationDetails: operationDetailsReducer,
  },
});
