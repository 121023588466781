/* eslint-disable no-param-reassign */

import { ZonesPageTabsEnum } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type ZonesPageTableDataState = {
  currentTab: ZonesPageTabsEnum;
};

const initialState: ZonesPageTableDataState = {
  currentTab: ZonesPageTabsEnum.ZONES,
};

const zonesPageSlice = createSlice({
  name: "zones-page",
  initialState,

  reducers: {
    setCurrentTab: (state, action: PayloadAction<ZonesPageTabsEnum>) => {
      const draft = state;
      draft.currentTab = action.payload;
    },
  },
});

export const { setCurrentTab } = zonesPageSlice.actions;

export const { reducer: zonesPageReducer } = zonesPageSlice;
