import type { Color } from "@mui/material";
import { createTheme } from "@mui/material";

type ColorPartial = Partial<Color>;
declare module "@mui/material/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteColor extends ColorPartial {}
}

const theme = createTheme({
  shape: { borderRadius: 12 },
  palette: {
    common: { black: "#000", white: "#fff" },
    background: {
      paper: "#fff",
      default: "#edf6ff",
    },
    grey: {
      100: "#EBEBEB",
      300: "#BBBBBB",
      500: "#8D8D8D",
      700: "#5F5F5F",
      900: "#3A3A3A",
    },
    primary: {
      50: "#EDF6FF",
      100: "#D0E7FF",
      300: "#3297FC",
      500: "#204C64",
      700: "#0258AD",
      900: "#023971",
    },
    error: {
      main: "#EF4444",
      100: "#ef44448a",
      light: "#ffeaea",
    },
    success: {
      main: "#10BB81",
      light: "#d1f2e7",
    },
    warning: {
      main: "#F59F0B",
    },
  },

  typography: {
    fontFamily: `Kiro, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Oxygen, Cantarell, sans-serif`,
    fontWeightRegular: 400,
    h1: {
      fontSize: "48px",
    },
    h2: {
      fontSize: "44px",
    },
    h3: {
      fontSize: "40px",
    },
    h4: {
      fontSize: "36px",
    },
    h5: {
      fontSize: "32px",
    },
    h6: {
      fontSize: "28px",
    },
    subtitle1: {
      fontSize: "20px",
    },
    subtitle2: {
      fontSize: "22px",
    },
    body1: {
      fontSize: "14px",
    },
    body2: {
      fontSize: "12px",
    },
    caption: {
      fontSize: "10px",
    },
  },
});

export default theme;
