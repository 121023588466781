import type { TeamOperationCategory } from "~utils/types/global-types";

import type { TeamOperationDetailsModalsEnum, TeamOperationDetailsState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: TeamOperationDetailsState = {
  currentModal: null,
  operation: null,
};

const teamOperationDetailsSlice = createSlice({
  name: "team-operation-details-modals",
  initialState,

  reducers: {
    setCurrentModal: (state, action: PayloadAction<TeamOperationDetailsModalsEnum>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setTeamOperation: (state, action: PayloadAction<TeamOperationCategory>) => {
      const draft = state;
      draft.operation = action.payload;
    },
    closeModals: (state) => {
      const draft = state;
      draft.currentModal = null;
    },
  },
});

export const { setCurrentModal, setTeamOperation, closeModals } = teamOperationDetailsSlice.actions;

export const { reducer: teamOperationDetailsReducer } = teamOperationDetailsSlice;
