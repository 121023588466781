export enum AcceptedLanguages {
  en = "en",
  fr = "fr",
  ar = "ar",
  de = "de",
  es = "es",
  it = "it",
  ja = "ja",
  nl = "nl",
  pt = "pt",
  ru = "ru",
  zh = "zh",
  tr = "tr",
}

export const PASSWORD_RULES = {
  minCharsNbr: 8,
  minAlphabetsNbr: 1,
  minDigitsNbr: 1,
  minSpecialCharsNbr: 1,
};
export const STRING_SEPARATOR = ",";
export const WHITE_SPACE_REGEXP = /\s+/gu;
export const STRING_SEPARATOR_REGEXP = /\s*,\s*/gu;
export const PRICE_SEPARATOR = "to";
export const TO_SEPARATOR_REGEXP = /\s*to\s*/u;
export const PRICE_SEPARATOR_REGEXP = TO_SEPARATOR_REGEXP;
export const PRICE_FORMAT_REGEXP = /^\d+.+\s*to\s*\d+.+$/u;
export const OPENING_HOURS_REGEXP = /^(?<openingHours>.+\s\d{2}:\d{2}\sto\s\d{2}:\d{2}\s*,?\s*)+$/u;

export const DEFAULT_TEAM_NAMES = [
  "Technique",
  "Reservation",
  "Food And Beverage",
  "Housekeeping",
  "Spa",
  "Front Office",
];

export const VALID_PASSWORD_REGEXP = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[ .@#$%^&+_=()\-!?,;:'"/]).{8,}$/u;
export const VALID_EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u;
export const VALID_PHONE_REGEXP = /^[0-9]{8,}$/u;
export const VERIFICATION_CODE_EXPIRATION_DURATION = 15;
export const VERIFICATION_CODE_REGEXP = /^[0-9]{6}$/u;
export const RESET_CODE_MESSAGE = "//password/reset";
export const STANDARD_FORMAT_FOR_DAY_MONTH_YEAR = "DD/MM/YYYY";
export const MAX_NUMBER_FOR_LIMITED_LANGUAGES = 5;
