import type { Consumer } from "~utils/types";

export enum ZonesPageTabsEnum {
  ZONES = "zones",
  ROOMS = "rooms",
}

export type IUseZonesPageState = () => {
  setCurrentTab: Consumer<ZonesPageTabsEnum>;
};
